.form__inner{
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 68px;
	@media (max-width: 970px){
		gap: 40px;
	}
	@media (max-width: 620px){
		gap: 8px;
	}
	.form__title{
		margin-bottom: 18px;
		text-align: left;
	}
}
form {
	display: flex;
	flex-direction: column;
	row-gap: 18px;
	@media (max-width: 620px){
		gap: 10px;
	}
	.form__item{
		display: flex;
		flex-direction: column;
	}
	.form__inp{
		padding: 13px 15px 10px 15px;
		width: 430px;
		height: 45px;
		background-color: var(--item-color);
		font-size: 18px;
		font-weight: 600;
		border-radius: 5px;
		-webkit-box-shadow: 10px 82px 50px 0px rgba(0, 0, 0, 0.01), 4px 36px 37px 0px rgba(0, 0, 0, 0.02), 1px 9px 20px 0px rgba(0, 0, 0, 0.03);
		-moz-box-shadow: 10px 82px 50px 0px rgba(0, 0, 0, 0.01), 4px 36px 37px 0px rgba(0, 0, 0, 0.02), 1px 9px 20px 0px rgba(0, 0, 0, 0.03);
		box-shadow: 10px 82px 50px 0px rgba(0, 0, 0, 0.01), 4px 36px 37px 0px rgba(0, 0, 0, 0.02), 1px 9px 20px 0px rgba(0, 0, 0, 0.03);
		@media (max-width: 850px){
			width: 340px;
		}
		@media (max-width: 620px){
			width: 250px;
			font-size: 15px;
		}
	}
	label {
		font-size: 18px;
		font-weight: 600;
		@media (max-width: 620px){
			font-size: 15px;
		}
	}
	.form__btn{
		margin-top: 12px;
		@media (max-width: 520px){
			align-self: center;;
		}
	}
}
.image__box{
	width: 387px;
	height: 387px;
	border-radius: 50%;
	overflow: hidden;
	@media (max-width: 890px){
		width: 200px;
		height: 200px;
	}
	@media (max-width: 520px){
		display: none;
	}
}