/* Base */
@import "./base/reset";
@import "./base/vars";
@import "./base/base";
@import "./base/containers";

/* Blocks */
@import "blocks/_about.scss";
@import "blocks/_advanteges.scss";
@import "blocks/_footer.scss";
@import "blocks/_form.scss";
@import "blocks/_gallery.scss";
@import "blocks/_galleryPage.scss";
@import "blocks/_geo.scss";
@import "blocks/_header.scss";
@import "blocks/_hero.scss";
@import "blocks/_menu.scss";
@import "blocks/_services.scss";
@import "blocks/_tarifs.scss";
