.about {
	position: relative;
}
.about__inner{
	display: flex;
	flex-direction: column;
	align-items: center;
	.about__title{
		margin-bottom: 46px;
		@media (max-width: 480px){
			margin-bottom: 26px;
		}
	}
}
.about__list{
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 20px;
	@media (max-width: 620px){
		gap: 40px;
	}
	.about__item{
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: 20px;
		.about__item-img{
			border-radius: 50%;
			width: 280px;
			transition: all .4s ease;
			-webkit-transition: ease all .2s;
  		-moz-transition: ease all .2s;
  		-o-transition: ease all .2s;
			transition: ease all .2s;
		}
		.about__item-link{
			position: absolute;
			width: 100%;
			height: 100%;
		}
		.about__item-text{
			font-weight: 600;
			position: relative;
		}
		.about__item-text:before{
			content: '';
			position: absolute;
			bottom: -5px;
			left: 0;
			height: 2px;
			width: 0%;
			background-color: var(--accent-bg);
			-webkit-transition: ease all .2s;
  		-moz-transition: ease all .2s;
  		-o-transition: ease all .2s;
			transition: ease all .2s;
		}
	}
}
.about__decor{
	bottom: 5px;
	left: 20px;
}

.about__item:hover{
	.about__item-text::before{
		width: 100%;
	}
	.about__item-img{
		transform: scale(1.1);
	}
}
