.menu__inner{
	display: flex;
	flex-direction: column;
	align-items: center;
	.menu__title{
		margin-bottom: 46px;
		@media (max-width: 480px){
			margin-bottom: 26px;
		}
	}
	.menu__img{
		width: 100%;
	}
}