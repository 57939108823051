.tarifs__inner{
	display: flex;
	flex-direction: column;
	align-items: center;
	.tarifs__title{
		margin-bottom: 46px;
		@media (max-width: 480px){
			margin-bottom: 26px;
		}
	}
	.tarifs__list{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		width: 100%;
		gap: 80px;
	}
}
.tarifs__item{
	display: flex;
	flex-direction: column;
	width: 45%;
	row-gap: 20px;
	@media (max-width: 1100px){
		width: 60%;
	}
	@media (max-width: 850px){
		width: 75%;
	}
	@media (max-width: 700px){
		width: 85%;
	}
	@media (max-width: 620px){
		width: 100%;
	}
	.tarifs__item-title{
		font-size: 18px;
	}
	.tarifs__rows{
		display: flex;
		flex-direction: column;
		gap: 10px;
		.tarifs__row{
			position: relative;
			display: flex;
			justify-content: space-between;
			@media (max-width: 620px){
				flex-direction: column;
			}
			p {
				font-size: 16px;
				background-color: var(--page-bg);
				padding: 0 2px;
			}
		}
		
	}
}
.tarifs__row::before{
	content: '';
	position: absolute;
	width: 100%;
	height: 1px;
	top: 15px;
	border-bottom: 1px dashed var(--text-color);
	z-index: -1;
	@media (max-width: 620px){
		display: none;
	}
}