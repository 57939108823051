.geo__inner{
	display: flex;
	flex-direction: column;
	align-items: center;
	.geo__title{
		margin-bottom: 40px;
		@media (max-width: 480px){
			margin-bottom: 20px;
		}
	}
	iframe {
		border-radius: 30px;
		border: 1px solid #999999;
		@media (max-width: 850px){
			height: 350px;
		}
	}
}