:root {
	--container-width: 1280px;
	--container-padding: 20px;
	
	--font-main: 'Montserrat-site', sans-serif;
	--font-accent: 'Optima-c', sans-serif;
	
	--page-bg: #F5F5F5;
	--accent-bg: #B50000;
	--item-color: #fff;
	--text-color: #050505;
	--text-color-light: #fff;

	--box-shadow: 0px 69px 42px 0px rgba(0, 0, 0, 0.02),  0px 31px 31px 0px rgba(0, 0, 0, 0.03), 0px 8px 17px 0px rgba(0, 0, 0, 0.03);
	
}

@font-face {
	font-family: 'Montserrat-site';
	src: url(./../fonts/Montserrat-Black.ttf) format('truetype');
	font-weight: 900;
}
@font-face {
	font-family: 'Montserrat-site';
	src: url(./../fonts/Montserrat-Medium.ttf) format('truetype');
	font-weight: 500;
}
@font-face {
	font-family: 'Montserrat-site';
	src: url(./../fonts/Montserrat-SemiBold.ttf) format('truetype');
	font-weight: 600;
}
@font-face {
	font-family: 'Optima-c';
	src: url(./../fonts/TenorSans-Regular.ttf) format('truetype');
}