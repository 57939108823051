.hero {
	margin-top: 100px;
	background: url(./../img/heroImage.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 652px;
	@media (max-width: 850px){
		height: 570px;
	}
	.hero__inner{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: start;
		height: 652px;
		@media (max-width: 850px){
			height: 570px;
		}
	}
}
.hero__desc{
	max-width: 610px;
	color: var(--text-color-light);
	margin-bottom: 20px;
	@media (max-width: 750px){
		font-size: 14px;
	}
}