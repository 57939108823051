.services{
	position: relative;
}
.services__inner{
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	.services__title{
		margin-bottom: 46px;
		@media (max-width: 480px){
			margin-bottom: 26px;
		}
	}
}
.services__list{
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 20px;
	@media (max-width: 620px){
		gap: 40px;
	}
	.services__item{
		position: relative;
		width: 295px;
		height: 199px;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: var(--item-color);
		border: 1px solid rgba(0,0,0,.20);
		border-radius: 10px;
		-webkit-box-shadow: var(--box-shadow);
		-moz-box-shadow: var(--box-shadow);
		box-shadow: var(--box-shadow);
		-webkit-transition: ease all .2s;
  	-moz-transition: ease all .2s;
  	-o-transition: ease all .2s;
		transition: ease all .2s;
		overflow: hidden;
		.services__item-text{
			padding-top: 14px;
			font-weight: 600;
		}
		.services__item-img{
			width: 100%;
			height: 145px;
			-webkit-transition: ease all .2s;
  		-moz-transition: ease all .2s;
  		-o-transition: ease all .2s;
			transition: ease all .2s;
		}
	}
}
.services__item-link{
	position: absolute;
	width: 100%;
	height: 100%;
}
.services__decor{
	right: 0;
	top: -36px;
}

.services__item:hover{
	border: 2px solid var(--accent-bg);
	.services__item-img{
		transform: translateY(-8px) scale(1.1);
	}
}