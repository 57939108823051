.advantages__inner{
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	.advantages__title{
		margin-bottom: 46px;
		@media (max-width: 480px){
			margin-bottom: 26px;
		}
	}
}
.advantages__list{
	display: flex;
	justify-content: center;
	gap: 20px;
	flex-wrap: wrap;
	@media (max-width: 620px){
		gap: 40px;
	}
}
.advantages__item{
	width: 295px;
	height: 263px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: var(--item-color);
	border: 1px solid rgba(0,0,0,.20);
	border-radius: 10px;
	-webkit-box-shadow: var(--box-shadow);
	-moz-box-shadow: var(--box-shadow);
	box-shadow: var(--box-shadow);
	@media (max-width: 1100px){
		height: 240px;
	}
	.advantages__item-text{
		padding: 15px 23px;
		text-align: center;
		font-weight: 600;
	}
	.advantages__item-img{
		width: 100%;
		height: 145px;
	}
}