footer{
	background-color: #fff;
	.footer__inner{
		display: flex;
		align-items: center;
		padding-top: 23px;
		padding-bottom: 60px;
		justify-content: space-between;
		position: relative;
	}
}
.footer__box{
	display: flex;
	align-items: center;
	column-gap: 52px;
	@media (max-width: 850px){
		column-gap: 20px;
	}
	.footer__nav-logo img {
		width: 53px;
	}
	.footer__info{
		display: flex;
		flex-direction: column;
		gap: 5px;

		a{
			display: flex;
			align-items: center;
			gap:15px;
			font-size: 20px;
			font-weight: 600;
			@media (max-width: 850px){
				font-size: 15px;
			}
			@media (max-width: 750px){
				font-size: 12px;
			}
			@media (max-width: 670px){
				font-size: 18px;
			}
			@media (max-width: 480px){
				font-size: 12px;
			}
		}
	}
}
.footer__row{
	display: flex;
	gap: 10px;
	
	.footer__links{
		margin-right: 40px;
		display: flex;
		gap: 15px;
		@media (max-width: 970px){
			margin-right: 0;
			gap: 5px;
		}
		@media (max-width: 670px){
			position: absolute;
			bottom: 10px;
			left: 60px;
		}
		@media (max-width: 400px){
			left: 20px;
		}
	}
	.footer__timetable{
		display: flex;
		flex-direction: column;
		align-items: end;
		gap: 2px;
		@media (max-width: 670px){
			position: absolute;
			bottom: 10px;
			right: 60px;
		}
		@media (max-width: 400px){
			right: 20px;
		}
		p{
			font-size: 16px;
			@media (max-width: 850px){
				font-size: 12px;
			}
			@media (max-width: 670px){
				font-size: 15px;
			}
			@media (max-width: 480px){
				font-size: 12px;
			}
		}
	}
}
.footer__btn{
	@media (max-width: 970px){
		width: 140px;
		font-size: 13px;
	}
}