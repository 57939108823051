header {
	background-color: #fff;
	width: 100%;
	position:fixed;
	top: 0;
	z-index: 999;
	.header__inner{
		display: flex;
		align-items: center;
		padding-top: 23px;
		padding-bottom: 24px;
		justify-content: space-between;
		@media (max-width: 850px){
			padding-top: 30px;
			padding-bottom: 30px;
		}
		@media (max-width: 650px){
			padding-top: 23px;
			padding-bottom: 24px;
		}
	}
}
.header__nav{
	display: flex;
	align-items: center;
	column-gap: 52px;
	@media (max-width: 1100px){
		column-gap: 20px;
	}
	@media (max-width: 700px){
		column-gap: 10px;
	}
	.header__nav-logo img{
		width: 53px;
		height: 53px;
	}
	.header__nav-list{
		display: flex;
		column-gap: 15px;
		.header__nav-item{
			position: relative;
			font-weight: 600;
			font-size: 16px;
			@media (max-width: 1100px){
				font-size: 14px;
			}
			@media (max-width: 700px){
				font-size: 13px;
			}
		}
	}
}
.header__row{
	display: flex;
	align-items: center;
	column-gap: 10px;
	position: relative;
	@media (max-width: 560px){
		display: none;
	}
	.header__links{
		display: flex;
		column-gap: 14px;
		margin-right: 10px;
		@media (max-width: 970px){
			column-gap: 5px;
			margin-right: 0;
		}
		@media (max-width: 650px){
			display: none;
		}
		.header__link{
			@media (max-width: 700px){
				width: 25px;
			}
		}
	}
	.header__timetable{
		text-align: right;
		font-weight: 600;
		@media (max-width: 1100px){
			font-size: 14px;
		}
		@media (max-width: 970px){
			font-size: 12px;
		}
		@media (max-width: 850px){
			position: absolute;
			top: -30px;
			right: 0;
		}
		@media (max-width: 650px){
			display: none;
		}
	}
}
.header__nav-item::before{
	content: '';
	width: 0%;
	height: 2px;
	background-color: var(--accent-bg);
	position: absolute;
	bottom: -5px;
	left: 0;
	-webkit-transition: ease all .2s;
  -moz-transition: ease all .2s;
  -o-transition: ease all .2s;
	transition: ease all .2s;
}
.header__nav-item:hover::before{
	width: 100%;
}
.header__btn{
	@media (max-width: 970px){
		width: 140px;
		font-size: 13px;
	}
	@media (max-width: 560px){
		display: none;
	}
}