.gallery-page__inner{
	display: flex;
	flex-direction: column;
	align-items: center;
	.gallery-page__title{
		margin-bottom: 46px;
		@media (max-width: 480px){
			margin-bottom: 26px;
		}
	}
}
.gallery-page-list{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
	@media (max-width: 600px){
		gap: 10px;
	}
	.gallery-page__item{
		width: 40%;
		border-radius: 8px;
		overflow: hidden;
		@media (max-width: 500px){
			width: 90%;
		}
	}
}