html {
	scroll-behavior: smooth;
}

body {
	background-color: var(--page-bg);
	color: var(--text-color);
  font-family: var(--font-main);
}

p {
	font-size: 18px;
	@media (max-width: 1100px){
		font-size: 16px;
	}
}

h1, h2, h3, h4, h5, h6 {
	font-family: var(--font-accent);
	line-height: 1;
}
a, a:hover, a:active {
	color: inherit;
}
.btn{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 200px;
	height: 44px;
	font-size: 16px;
	font-weight: 500;
	background-color: var(--accent-bg);
	color: var(--text-color-light);
	border-radius: 22px;
	-webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
	transition: all .3s ease;
}
.btn:hover{
	background-color: var(--text-color-light);
	color: var(--accent-bg);
	border: 1px solid var(--accent-bg);
}
.btn:active{
	background-color: var(--accent-bg);
	color: var(--text-color-light);
}
h1{
	font-size: 96px;
	color: var(--text-color-light);
	@media (max-width: 970px){
		font-size: 80px;
	}
	@media (max-width: 850px){
		font-size: 72px;
	}
	@media (max-width: 750px){
		font-size: 60px;
	}
	@media (max-width: 480px){
		font-size: 40px;
	}
}
.section__title {
	font-size: 32px;
	text-align: center;
	@media (max-width: 480px){
		font-size: 26px;
	}
}
section {
	margin-bottom: 80px;
}
.decorText{
	font-size: 128px;
	font-weight: 900;
	color: rgba(0, 0, 0, .25);
	position: absolute;
	z-index: -1;
	text-transform: uppercase;
	top: 5px;
	@media (max-width: 1230px){
		font-size: 100px;
		top: 10px;
	}
	@media (max-width: 970px){
		font-size: 80px;
		top: 20px;
	}
	@media (max-width: 850px){
		font-size: 70px;
		top: 25px;
	}
	@media (max-width: 700px){
		font-size: 60px;
		top: 35px;
	}
	@media (max-width: 580px){
		font-size: 50px;
		top: 45px;
	}
	@media (max-width: 480px){
		font-size: 40px;
		top: 30px;
	}
	@media (max-width: 400px){
		font-size: 35px;
	}
}
.decor {
	position: absolute;
	z-index: -1;
}
.fixed__link{
	position: fixed;
	z-index: 999;
}
.fixed__link1{
	bottom: -15px;
	right: 12px;
}
.fixed__link2{
	bottom: 20px;
	left: 20px;
}