.gallery__inner{
	display: flex;
	flex-direction: column;
	align-items: center;
	.gallery__title{
		margin-bottom: 40px;
		@media (max-width: 480px){
			margin-bottom: 20px;
		}
	}
}
.gallery__list{
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 20px;
	@media (max-width: 580px){
		gap: 10px;
	}
	.gallery__img1{
		width: 40%;
		@media (max-width: 850px){
			width: 45%;
		}
	}
	.gallery__img2{
		width: 40%;
		@media (max-width: 850px){
			width: 45%;
		}
	}
	.gallery__img3{
		width: 15%;
		@media (max-width: 850px){
			width: 21%;
		}
	}
	.gallery__img4{
		width: 32%;
		@media (max-width: 850px){
			width: 40%;
		}
		@media (max-width: 580px){
			width: 30%;
		}
	}
	.gallery__img5{
		width: 23%;
		@media (max-width: 850px){
			width: 40%;
		}
		@media (max-width: 580px){
			width: 30%;
		}
	}
	.gallery__list-text{
		width: 40%;
		font-weight: 600;
		@media (max-width: 1230px){
			font-size: 14px;
		}
		@media (max-width: 970px){
			font-size: 12px;
		}
		@media (max-width: 850px){
			width: 50%;
			font-size: 15px;
		}
		@media (max-width: 750px){
			font-size: 12px;
		}
		@media (max-width: 580px){
			width: 90%;
			text-align: center;
		}
	}
}